
body[data-layout-mode="dark"] {
    background-color: $gray-dark-200;
    color: $gray-dark-600;


    .h1,.h2,.h3,.h4,.h5,.h6,
    h1,h2,h3,h4,h5,h6 {
        color: $gray-dark-600;
    }
}
  
.navbar {
    background-color: #fff;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.05);
    padding: 1rem;
    border-bottom: 1px solid #eee;

    .navbar-brand {
        img {
        height: 30px;
    }
}

    .nav-link {
        font-size: 1.2rem;
        margin-right: 1.5rem;
        color: #333;
        &:hover {
        color: #555;
        }
    }
}

.hero-image{
    background-image: url("../../../images/base.png");
    height: 80%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.section{
    position: relative;
    padding-top: 80px;
    padding-bottom: 80px;
}

.foot {
    // background-color: #0f1b42;
    // color: rgba(255, 255, 255, 0.5);
    // padding-top: 80px;
    // padding-bottom: 40px;
    padding: 2rem;
    box-shadow: 1rem 0.5rem 0rem rgba(0,0,0,0.05);
    border-top: 1px solid #eee;
    
    
}
.footer-list-title {
    font-size: 16px;
    color: #ffffff;
  }

  .pp-rel{
    position: relative;
    .pp-abs{
        position: absolute;
        top: 70px;
        left: 48px;
        background-color: #EAEAEF;
        border: 1.5px solid #FFFFFF;
        box-shadow: 0px 60px 60px rgba(29, 29, 31, 0.24);
        cursor: pointer;
    }
    .pp-spinner{
        position: absolute;
        top: 25px;
        left: 15px;
        cursor: pointer;
    }
    .upload-input{
        display: none;
    }
}

.upload-input{
    display: none;
}

.upload-video{
    border: 2px solid #EBEBEB;
    padding: 10px;
    border-radius: 8px;
    background-color: #EBEBEB;
}

.special-label {
    display: none;
}

.pp-size{
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #84868B;
}

.card-courses .img-fluid{
    max-width: 100%;
    height: 132px;
}

.paginationBttns {
    width: 80%;
    height: 40px;
    list-style: none;
    display: flex;
    padding: 0;
  }
  
  .paginationBttns a {
    padding: 10px;
    margin-right: 8px;
    border-radius: 5px;
    border: 1px solid #e9e9ef;
    color: grey;
    cursor: pointer;
  }
  
  .paginationBttns a:hover {
    background-color: white;
    border: 1px solid #fff;
  }
  
  .paginationActive a {
    color: #fff !important;
    background-color: #5156be;
  }
  
  .paginationDisabled a {
    color: grey;
    background-color: #fff;
  }

  .paginationDisabled a:hover {
    color: grey;
    border: 1px solid #e9e9ef;
  }

  .demo-chart{
    font-size: 14px;
    font-weight: 600;
    // color: #495057 !important;
  }

  .course-desc{
    height: 20px;
  }

  .card-courses .card-body{
    padding: 15px 10px;
  }

  .card-courses .card{
    border-radius: 10px !important;
  }
  .course-title{
    font-weight: 700;
    // line-height: 21.86px;
  }

  .card-courses, .card-img, .card-img-top{
    border-radius: 9.83px 9.83px 0 0;
  }

  .card-courses, .card{
    border-radius: 9.83px;
    // box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
  }

  .card-course, .is-completed{
    padding: 5px;
    background-color: #ecf9f7;
    border-radius: 39.33px;
    color: #03A48B;
    font-weight: 600;
    font-size: 14px;
    line-height: 19.12px;
    width: 40%;
    padding-left: 10px;
  }

  .card-course, .is-incompleted{
    padding: 5px;
    background-color: #fbf1f1;
    border-radius: 39.33px;
    color: #CF3A3A;
    font-weight: 600;
    font-size: 14px;
    line-height: 19.12px;
    width: 40%;
    padding-left: 10px;
  }

.apexcharts-yaxis text, .apexcharts-xaxis text{
    color: #000 !important;
    fill: #000 !important;
}

.submission .avatar-xl{
    height: 6.5rem;
    width: 6.5rem;
}

.placeholder {
  background-color: #c0c0c0 !important;
 }

 .profile .btn{
  border-radius: 0 !important;
 }

 .placeholder {
  background-color: #c0c0c0 !important;
 }
